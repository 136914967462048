const TYPES = [
  'Aucun',
  'Asiatique',
  'Japonais',
  'Thailandais',
  'Chinois',
  'Italien',
  'FastFood',
  'Salon de thé',
  'Bubble tea',
  'Américain',
  'Français',
  'Sandwich',
  'Burger',
  'Vietnamien',
  'Chicken',
  'Glace',
  'Coréen',
  'Indien',
  'Volonté',
  'Halal',
  'Mexicain',
  'Pizza',
  'Sushis',
  'Brunch',
  'Healthy',
  'Salade',
  'Marocain',
  'Fruits de mer',
  'Pâtes',
  'Maison de Bao',
  'Cusine chinoise',
  'Cuisine du monde',
  'Buffet',
  'Buffet à volonté',
  'Pakistanais',
  'Orientale',
  'Végan',
  'Hallal',
  'Spécialité Hawaïenne',
  'Ramen'
]

export function getBusinessTypes(arr) {
  if (arr === undefined) return ''
  let str = ''

  arr.forEach(id => {
    if (id !== -1 && str === '')
      str = str.concat('', TYPES[id])
    else if (id !== -1 && str !== '')
      str = str.concat(' • ', TYPES[id])
  });
  return str
}

export function parseSchedule(sc) {
  const schedule = Array(7);
  for (let i = 0; i < 7; ++i) {
    schedule[i] = [];
    if (sc && sc[i] && sc[i].dailySchedule) {
      const daySchedule = sc[i].dailySchedule;
      if (daySchedule) {
        for (let ii = 0; ii < daySchedule.length; ++ii) {
          const timeRange = daySchedule[ii].split('-');
          schedule[i].push({
            start: timeRange[0],
            end: timeRange[1],
          });
        }
      }
    }
  }

  return schedule;
}

export function computeDiscount(price, discount, getDiscountedPrice = false) {
  if (getDiscountedPrice) {
    return price * (1 - discount / 100);
  }
  return price * (discount / 100);
}

export function paymentIdToName(id) {
  switch (id) {
    case 1: return 'Espèce';
    case 2: return 'Carte bancaire';
    case 3: return 'Ticket restaurants';
    case 4: return 'Chèques';
    case 5: return 'Chèques vacances';
    case 6: return 'Avoir';
    case 7: return 'Autre';
    case 8: return 'En ligne';
    default: return '?';
  }
}
