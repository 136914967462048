// @flow

import React, { Component } from 'react';
import { Breakpoint } from 'react-socks';
import { Container, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import Swal from 'sweetalert2';
import differenceInHours from 'date-fns/differenceInHours';
import * as animationData from '../assets/loading.json'
import * as animationData404 from '../assets/404.json'
import * as animationCooking from '../assets/cooking.json'
import '../styles/Restaurant.scss'
import { updateRestoInfo, resetCart, applyDiscount, updateTable } from '../actions/cartAction';
import { updateRestoSettings, updateDeliverySchedule, updateOrderSchedule, updateDeliverySettings, updateTakeawaySettings, setRestoId, setQrId } from '../actions/settingsAction';
import LazyLoad from 'react-lazyload';
import image1 from "../assets/allergen/1.png";
import image2 from "../assets/allergen/2.png";
import image3 from "../assets/allergen/3.png";
import image4 from "../assets/allergen/4.png";
import image5 from "../assets/allergen/5.png";
import image6 from "../assets/allergen/6.png";
import image7 from "../assets/allergen/7.png";
import image8 from "../assets/allergen/8.png";
import image9 from "../assets/allergen/9.png";
import image10 from "../assets/allergen/10.png";
import image11 from "../assets/allergen/11.png";
import image12 from "../assets/allergen/12.png";
import image13 from "../assets/allergen/13.png";
import image14 from "../assets/allergen/14.png";

import { BsCart2 } from "react-icons/bs"
import { MdHistory } from "react-icons/md"
import { GiPollenDust } from "react-icons/gi"


import $ from "jquery"

import { parseSchedule, computeDiscount } from './Utils'
import ProductCartRowRealtime from './ProductCartRowRealtime'
import { CartType, ProductType } from '../flowObjectType'
import { firestore, analytics, database } from '../services/firebase'
import ProductDetailRealtime from './ProductDetailRealtime'
import Cart from './Cart'

const ImageData = {}
ImageData['gluten'] = image1
ImageData['arachide'] = image2
ImageData['coque'] = image3
ImageData['celeri'] = image4
ImageData['moutarde'] = image5
ImageData['oeuf'] = image6
ImageData['lait'] = image7
ImageData['poisson'] = image9
ImageData['crustace'] = image10
ImageData['mollusque'] = image11
ImageData['soja'] = image12
ImageData['anhydride'] = image13
ImageData['sesame'] = image8
ImageData['lupin'] = image14


// color
// text black
// background #F1F1F1
// blue #00CCBB
// yellow #FDC151
// red #FF4B4B

type Props = {
  match: Object,
  cart: CartType,
  myCart: CartType,
  settings: Object,
  updateRestoInfo: Function,
  updateRestoSettings: Function,
  updateDeliverySchedule: Function,
  updateOrderSchedule: Function,
  updateTable: Function,
  resetCart: Function,
  updateTakeawaySettings: Function,
  updateDeliverySettings: Function,
  setQrId: Function,
  setRestoId: Function
}

type State = {
  canOrder: boolean,
  settings: Object,
  informations: Object,
  showProductDetail: boolean,
  showCart: boolean,
  restaurant: Object,
  sections: Array<Object>,
  selectedProduct: ?ProductType,
  invalidQrCode: boolean,
  selectedCategoryIdx: number,
  selectedIdx: ?number,
  table: ?string
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationCooking.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

function goToAnchor(event: Object, id: string) {
  const productPositionY = $(`#section-${id}`).offset().top - 65
  // $(event.target).css('backgroundColor', 'white')
  $("html, body").animate({ scrollTop: productPositionY }, 500);
  event.preventDefault();
}

let savedSticky = 0
function handleStickyHeader(header) {
  let sticky = header[0].offsetTop
  if (header.hasClass("sticky")) {
    sticky = savedSticky
  }

  if (window.pageYOffset > sticky) {
    savedSticky = sticky
    header.addClass("sticky");
  } else {
    header.removeClass("sticky");
  }
}
function handleActiveSection(header) {

  const topMenuHeight = header.outerHeight() + 10
  const menuItems = header.find(".options-btn")
  const scrollItems = menuItems.map(function () {
    var item = $($(this).attr("href"));
    if (item.length) { return item; }
    return null
  });

  var fromTop = window.scrollY + topMenuHeight;
  var lastId;
  // Get id of current scroll item

  var cur = scrollItems.map(function () {
    if ($(this).offset().top < fromTop)
      return this;
    return null
  });
  // Get the id of the current element
  cur = cur[cur.length - 1];
  var id = cur && cur.length ? cur[0].id : "";

  if (lastId !== id) {
    lastId = id;
    // Set/remove active class
    if (id === "") return
    menuItems.removeClass("selected")
    const selectedItem = menuItems.filter("[href='#" + id + "']")
    selectedItem.addClass("selected");
    if (selectedItem[0]) {
      selectedItem[0].scrollIntoView({
        behavior: 'instant',
      });
    }
  }

}

const CategoryRow = (props) => (
  <>
    <a href={`#section-${props.category.id}`} className={`options-btn ${props.selected ? 'selected' : ''}`} onClick={(event) => { goToAnchor(event, props.category.id) }}>
      {props.category.name}
    </a>
  </>
)

const Section = (props) => (
  <div>
    <h3 className="section" id={`section-${props.id}`}>
      <div className="section-title">{props.title.toUpperCase()}{props.maxQuantity > 0 ? ` # ${props.maxQuantity} / personne` : ''}</div>
    </h3>
    {
      props.description && (
        <div className="section-description">
          {props.description}
        </div>
      )
    }
  </div >
)

const ProductRow = (props) => {

  const { product } = props
  let cartProducts = [];

  if (typeof props.cartProducts === 'object' && props.cartProducts !== null) {
    const products = props.cartProducts ? [].concat.apply([], Object.values(props.cartProducts)) : []
    cartProducts = products ? products.filter(e => e.id === props.product.id) : [];
  }
  else {
    cartProducts = props.cartProducts ? props.cartProducts.filter(e => e.id === props.product.id) : [];
  }

  const totalQuantity = cartProducts.length > 0 ? cartProducts.reduce((total, p) => total + p.quantity, 0) : 0;
  const price = `${product.unitPrice.toFixed(2)} €`
  const unitPrice = parseFloat(product.unitPrice);
  const allergen = product.allergene
  let discountPrice = ``;
  let hasDiscount = false;
  let discountLabel = '';
  if (product.tableDiscount && product.tableDiscount.discount) {
    discountPrice = unitPrice * (1 - product.tableDiscount.discount / 100)
    hasDiscount = true;
    discountLabel = `${product.tableDiscount.discount}% `
  }
  if (product.name == '90-Chedar surimi roll 4pcs')
    console.log(product)
  return (
    <div className={`product-box ${cartProducts.length > 0 ? 'selected' : ''}`} onClick={props.toggleModal(product)}>
      <div className="product-text-container">

        {allergen && allergen.length > 0 && (
          <div className="product-allergen">
            {allergen.map((name) =>
              <img
                alt="produit"
                className="product-img"
                src={ImageData[`${name}`]}
                width={30}
                height={30}
              />
            )}
          </div>
        )}

        {product.maxQuantity && product.maxQuantity > 0 && (
          <div className={`product-max-quantity`}>
            {product.maxQuantity} / pers
          </div>
        )}

        <div className="product-box-name">
          <div className="product-name">{product.name}</div>
        </div>
        {product.description !== "" && (
          <div className="product-description">
            <p>
              {product.description}
            </p>
          </div>
        )}

        {hasDiscount && (
          <div className={`product-price ${!product.canOrder ? 'product-not-available' : ''}`} style={{ alignItems: 'center' }}>
            <span style={{ textDecoration: 'line-through' }}>{unitPrice.toFixed(2)} €</span>
            <span>{`    -${discountLabel} soit ${discountPrice.toFixed(2)} €`}</span>
            {!product.canOrder && (`indisponible`)}
          </div>
        )}
        {!hasDiscount && product.unitPrice > 0 && (
          <div className={`product-price ${!product.canOrder ? 'product-not-available' : ''}`}>
            {price}
            {!product.canOrder && (`indisponible`)}
          </div>
        )}
      </div>
      {
        product.image.path !== '' && (
          <div className="image-container">
            <LazyLoad throttle={200} height={300} key={product.id} once>
              <img
                className="img"
                src={product.image.path}
                alt=""
              />
            </LazyLoad>
          </div>
        )
      }
      {
        cartProducts.length > 0 && (
          <div className="product-box-quantity">
            {totalQuantity}
          </div>
        )
      }
    </div >
  )
}


class Restaurant extends Component<Props, State> {
  unsubscribe: Object
  refRestaurant: Object
  refProduct: Object
  refCategory: Object
  refDiscount: Object
  refOption: Object
  readOnly: boolean
  products: Array<Object>
  categories: Array<Object>
  discounts: Array<Object>
  options: Array<Object>
  selectCategory: Function

  constructor(props: Object) {
    super(props);

    this.unsubscribe = null
    this.refRestaurant = null
    this.refProduct = null
    this.refCategory = null
    this.refDiscount = null
    this.refOption = null

    this.readOnly = false
    this.products = []
    this.categories = []
    this.discounts = []
    this.options = []

    this.state = {
      canOrder: false,
      settings: null,
      informations: null,
      showProductDetail: false,
      showCart: false,
      restaurant: null,
      sections: [],
      selectedProduct: null,
      invalidQrCode: false,
      selectedCategoryIdx: 0,
      selectedIdx: undefined,
      table: null
    }
  }

  componentDidMount() {
    const cartCreatedAt = new Date(this.props.cart.createdAt);
    if (this.props.cart.flushCart === true || isNaN(cartCreatedAt) || differenceInHours(new Date(), cartCreatedAt) >= 5)
      this.props.resetCart();
    const qrid = this.props.match.params.qrid
    if (qrid) {
      if (qrid.length === 41) {
        this.handleTableQr(qrid)
      } else {
        this.setState({ invalidQrCode: true })
        Swal.fire("QR Code non valide", "Veuillez réessayer un autre QR Code", "error");
      }
    } else {
      Swal.fire("QR Code non valide", "Veuillez réessayer un autre QR Code", "error");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.myCart !== prevState.someValue) {
      return { someState: nextProps.someValue };
    }
    else return null;
  }


  handleTableQr = (barcode) => {
    const restoId = barcode.split('-')[0]
    const tableId = barcode.split('-')[1]
    const ref = firestore().collection(`Restaurant/${restoId}/QRCode`).doc(tableId)
    ref.get()
      .then(doc => {
        if (!doc.exists) {
          setTimeout(() => {
            this.setState({ invalidQrCode: true })
          }, 200)
          return false
        } else {
          const qrcode = doc.data()
          this.initRestaurant(restoId)
          this.props.setRestoId(restoId)
          this.props.setQrId(tableId)
          this.props.updateTable(qrcode.table)
          this.setState({ table: qrcode.table })
          ref.onSnapshot(this.onQrCodeUpdate)
          return true
        }
      })
      .catch((err) => {
        console.log(err)
        if (window.ReactNativeWebView !== undefined) {
          this.props.resetCart();
          window.ReactNativeWebView.postMessage("")
        }
        this.setState({ invalidQrCode: true })
      })
  }

  onQrCodeUpdate = (querySnapshot: Object) => {
    if (!querySnapshot.exists) {
      if (window.ReactNativeWebView !== undefined) {
        this.props.resetCart();
        window.ReactNativeWebView.postMessage("")
      }
      return
    }
    const qrCode = querySnapshot.data()
    if (qrCode.status === 3)
      if (window.ReactNativeWebView !== undefined) {
        this.props.resetCart();
        window.ReactNativeWebView.postMessage("")
      }
  }


  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe()
  }

  loadScript = () => {
    const header = $("#sticky-header");
    if (header.length) {
      window.onscroll = () => {
        handleActiveSection(header)
        // handleStickyHeader(header)
      }
    }
  }

  initRestaurant = (restoId) => {
    this.refRestaurant = firestore().collection('Restaurant').doc(restoId)
    this.refProduct = firestore().collection(`Restaurant/${restoId}/Product`)
    this.refOption = firestore().collection(`Restaurant/${restoId}/Rubric`)
    this.refCategory = firestore().collection(`Restaurant/${restoId}/Category`).where('status', '==', 1)
    this.refDiscount = firestore().collection(`Restaurant/${restoId}/Discount`)

    this.unsubscribe = this.refRestaurant.onSnapshot(this.onRestoUpdate)
    this.unsubscribeProduct = this.refProduct.onSnapshot(this.onProductUpdate)

    const productP = this.refProduct.get()
    const categoryP = this.refCategory.get()
    const discountP = this.refDiscount.get()
    const optionP = this.refOption.get()

    Promise.all([productP, categoryP, discountP, optionP])
      .then(([product, category, discount, option]) => {
        const products = []
        const categories = []
        const discounts = []
        const options = []

        product.forEach((doc) => {
          const { createdAt, ...data } = doc.data()
          products.push({ id: doc.id, ...data })
        });
        category.forEach((doc) => {
          const { createdAt, ...data } = doc.data()
          categories.push({ id: doc.id, ...data })
        });
        discount.forEach((doc) => {
          const { createdAt, ...data } = doc.data()
          discounts.push({ id: doc.id, ...data })
        });
        option.forEach((doc) => {
          const { id, products, shared, ...data } = doc.data()
          options.push({ id: doc.id, ...data })
        })
        this.products = products
        this.categories = categories.sort((a, b) => (a.index < b.index ? -1 : 1))
        this.discounts = discounts
        this.options = options
        this.getSections()
        return true
      })
      .catch(err => {
        console.log(err)
        return false
      })
  }

  onRestoUpdate = (querySnapshot: Object) => {
    const restaurant = querySnapshot.data()
    const orderSchedule = parseSchedule(restaurant.settings.orderSchedule);
    this.props.updateRestoInfo(restaurant.informations.id, restaurant.informations.name, restaurant.informations.postalCode, restaurant.state.canOrder, restaurant.informations);
    this.props.updateRestoSettings(restaurant.informations.id, restaurant.settings.color, restaurant.settings.autoAcceptOrder, restaurant.settings.delivery, restaurant.settings.takeaway, restaurant.settings.table);
    this.props.updateOrderSchedule(orderSchedule);

    analytics().logEvent('home_page_table', { restoId: restaurant.informations.id, restoName: restaurant.informations.name });

    setTimeout(() => {
      this.setState({
        settings: restaurant.settings,
        informations: restaurant.informations,
        restaurant: restaurant,
        canOrder: restaurant.state.canOrder
      })
      this.loadScript()
    }, 200)
  }

  onProductUpdate = (querySnapshot: Object) => {
    const products = []
    querySnapshot.forEach((doc) => {
      const { createdAt, ...data } = doc.data()
      products.push({ id: doc.id, ...data })
    });
    this.products = products
    this.getSections()
  }

  toggleModal = (product) => () => {
    if (product.canOrder)
      this.setState(prevState => ({
        selectedProduct: { ...product },
        showProductDetail: !prevState.showProductDetail,
        selectedIdx: undefined
      }))
  }

  closeProductModal = () => {
    this.setState({ showProductDetail: false })
  }

  closeCartModal = () => {
    this.setState({ showCart: false })
  }

  getSections = (categoryId: null) => {
    const sections = []
    for (let i = 0; i < this.categories.length; i++) {
      sections.push({
        title: this.categories[i].name,
        description: this.categories[i].description,
        maxQuantity: this.categories[i].maxQuantity || 0,
        data: this.products.filter((item) => item.categoryId === this.categories[i].id).sort(((a, b) => (a.index < b.index ? -1 : 1))),
        index: i,
        id: this.categories[i].id
      })
    }
    this.setState({ sections })
  }

  onClickCartProduct = (index) => e => {
    this.setState({
      selectedProduct: this.props.myCart.products[index],
      showProductDetail: true,
      selectedIdx: index
    });
  };

  getOrderTotal = () => {
    let total = this.props.myCart.total;
    if (this.props.myCart.coupon !== null) {
      if (this.props.myCart.coupon?.discountType === 'percent') {
        total = Number.parseFloat(computeDiscount(total, this.props.myCart.coupon.discountPercent, true).toFixed(2));
      }
      else if (this.props.myCart.coupon?.discountType === 'price') {
        total -= this.props.myCart.coupon.discountPrice;
      }
    }
    return total ?? 0;
  }

  render() {
    if (this.state.invalidQrCode) {
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData404.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }
      return (
        <div id="not-found">
          <Breakpoint medium up>
            <Lottie
              options={defaultOptions}
            />
          </Breakpoint>
          <Breakpoint small down>
            <Lottie
              options={defaultOptions}
            />
          </Breakpoint>
        </div>
      )
    }
    if (!this.state.informations) {
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }

      return (
        <div className="center-loading">
          <Breakpoint medium up>
            <Lottie
              options={defaultOptions}
            />
          </Breakpoint>
          <Breakpoint small down>
            <Lottie
              options={defaultOptions}
            />
          </Breakpoint>
        </div>
      )
    }

    return (
      <Container className="container" id="main" >
        {
          this.props.settings.color && (
            <style> {`body {
              --website-color: ${this.props.settings.color}
            }`}
            </style>
          )
        }
        {/* <div className="header">
          <div className="restaurant-info">
            <div className="restaurant-name">{this.state.informations.name}</div>
            <div
              className="restaurant-info-table"
            >
              Vous êtes à la <b>TABLE {this.props.qrCode.stable}</b> - {this.props.qrCode.maxCustomer} couverts
            </div>
          </div>
        </div> */}

        <div className="category-container sticky" id="sticky-header">

          <div className="options">
            {this.categories.map((category, idx) =>
              <CategoryRow
                category={category}
                key={category.id}
                idx={idx}
                selected={idx === this.state.selectedCategoryIdx}
                getSections={this.getSections}
              />
            )}
          </div>
        </div>

        <Breakpoint customQuery="(min-width: 501px)">
          <div className="product-grid">
            <div className="product-container">
              {this.state.sections.map((section, index) => (
                <div key={index}>
                  <Section title={section.title} id={section.id} description={section.description} key={section.id} maxQuantity={section.maxQuantity} />
                  <div className="product">
                    {section.data.filter(p => p.displayApp).map((product) =>
                      <ProductRow
                        cartProducts={this.props.myCart.products}
                        product={product}
                        key={product.id}
                        toggleModal={this.toggleModal}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>

            {this.readOnly === false && !this.state.canOrder ? (
              <div className="footer">
                <div className="confirm-btn"><em>La commande est momentanement suspendu</em></div>
              </div>
            ) : this.readOnly === false ? (
              <div className="footer" onClick={() => this.setState({ showCart: true })}>
                <div className="confirm-btn">
                  <div className="footer-info">
                    <span>{this.state.informations.name}</span>
                    <span>Table {this.props.qrCode.table} ({this.props.qrCode.maxCustomer} CV)</span>
                  </div>
                  <div className="footer-logo">
                    <div className="logo" onClick={() => this.setState({ showCart: true })}><GiPollenDust size="1.2em" /></div>
                    <div className="logo" onClick={() => this.setState({ showCart: true })}><MdHistory size="1.2em" /></div>
                    <div className="logo" onClick={() => this.setState({ showCart: true })}><BsCart2 size="1.2em" /></div>
                  </div>
                  {/* <span>{`${this.getOrderTotal().toFixed(2)} €`}</span> */}
                </div>
              </div>
            ) : null}

          </div>
        </Breakpoint>

        <Breakpoint customQuery="(max-width: 500px)">
          <div className="product-grid">
            <div className="product-container">
              {this.state.sections.map((section, index) => (
                <div className="section-container" key={index}>
                  <Section title={section.title} id={section.id} description={section.description} key={section.id} maxQuantity={section.maxQuantity} />
                  {section.data.filter(p => p.displayApp).map((product) =>
                    <div key={product.id}>
                      <ProductRow
                        cartProducts={this.props.myCart.products}
                        product={product}
                        key={product.id}
                        toggleModal={this.toggleModal}
                      />
                      <div className="product-margin" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {this.readOnly === false && !this.state.canOrder ? (
            <div className="footer">
              <div className="confirm-btn"><em>La commande est momentanement suspendu</em></div>
            </div>
          ) : this.readOnly === false ? (
            <div className="footer" onClick={() => this.setState({ showCart: true })}>
              <div className="confirm-btn">
                <span>Table {this.props.qrCode.table} ({this.props.qrCode.maxCustomer} CV)</span>
                <span>Panier({this.props.myCart.totalProducts})</span>
              </div>
            </div>
          ) : null}

        </Breakpoint>


        <Modal
          show={this.state.showProductDetail}
          className="custom-map-modal"
          aria-labelledby="example-custom-modal-styling-title"
          onHide={this.closeProductModal}
          size="md"
        >
          <ProductDetailRealtime
            userId={this.props.settings.userId}
            myCart={this.props.myCart}
            product={this.state.selectedProduct}
            closeModal={this.closeProductModal}
            updateIndex={this.state.selectedIdx}
            options={this.options}
            readOnly={this.readOnly}
          />
        </Modal>

        <Modal
          show={this.state.showCart}
          className="custom-map-modal"
          aria-labelledby="example-custom-modal-styling-title"
          onHide={this.closeCartModal}
          size="md"
        >
          <Cart
            myCart={this.props.myCart}
            cart={this.props.cart}
            closeModal={this.closeCartModal}
            options={this.options}
            readOnly={this.readOnly}
          />
        </Modal>

      </Container >
    )
  }
}

// redux

const mapStateToProps = (state) => {
  return {
    // cart: state.cart,
    settings: state.settings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestoInfo: (a, b, c, d, e, f) => dispatch(updateRestoInfo(a, b, c, d, e, f)),
    updateRestoSettings: (a, b, c, d, e, f, g, h) => dispatch(updateRestoSettings(a, b, c, d, e, f, g, h)),
    applyDiscount: (d) => dispatch(applyDiscount(d)),
    updateOrderSchedule: (schedule) => dispatch(updateOrderSchedule(schedule)),
    updateDeliverySchedule: (schedule) => dispatch(updateDeliverySchedule(schedule)),
    updateDeliverySettings: (s) => dispatch(updateDeliverySettings(s)),
    updateTakeawaySettings: (s) => dispatch(updateTakeawaySettings(s)),
    resetCart: () => dispatch(resetCart()),
    setQrId: (id) => dispatch(setQrId(id)),
    setRestoId: (id) => dispatch(setRestoId(id)),
    updateTable: (table) => dispatch(updateTable(table))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Restaurant);
