export const UPDATE_SETTINGS = "UPDATE_SETTINGS"
export const UPDATE_DELIVERY_SCHEDULE = "UPDATE_DELIVERY_SCHEDULE"
export const UPDATE_ORDER_SCHEDULE = "UPDATE_ORDER_SCHEDULE"
export const UPDATE_DELIVERY_SETTINGS = "UPDATE_DELIVERY_SETTINGS"
export const UPDATE_TAKEAWAY_SETTINGS = "UPDATE_TAKEAWAY_SETTINGS"
export const SET_RESTO_ID = "SET_RESTO_ID"
export const SET_QR_ID = "SET_QR_ID"
export const SET_USER_ID = "SET_USER_ID"
export const SET_CART_WAITING = "SET_CART_WAITING"

export function updateRestoSettings(id, color, autoAcceptOrder, delivery, takeaway, table) {
  return {
    type: UPDATE_SETTINGS,
    payload: {
      id,
      color,
      autoAcceptOrder,
      delivery,
      takeaway,
      table
    }
  }
}

export function updateDeliverySchedule(deliverySchedule) {
  return {
    type: UPDATE_DELIVERY_SCHEDULE,
    payload: {
      deliverySchedule
    }
  }
}

export function updateOrderSchedule(orderSchedule) {
  return {
    type: UPDATE_ORDER_SCHEDULE,
    payload: {
      orderSchedule
    }
  }
}

export function updateDeliverySettings(deliverySettings) {
  return {
    type: UPDATE_DELIVERY_SETTINGS,
    payload: {
      deliverySettings
    }
  }
}

export function updateTakeawaySettings(takeawaySettings) {
  return {
    type: UPDATE_TAKEAWAY_SETTINGS,
    payload: {
      takeawaySettings
    }
  }
}

export function setRestoId(restoId) {
  return {
    type: SET_RESTO_ID,
    payload: {
      restoId,
    }
  }
}

export function setQrId(qrId) {
  return {
    type: SET_QR_ID,
    payload: {
      qrId,
    }
  }
}

export function setUserId(userId) {
  return {
    type: SET_USER_ID,
    payload: {
      userId,
    }
  }
}
