import { combineReducers } from 'redux';

import cartReducer from './cartReducer';
import settingsReducer from './settingsReducer';
import customerDetailsReducer from './customerDetailsReducer';

const rootReducer = combineReducers({
  cart: cartReducer,
  settings: settingsReducer,
  customerDetails: customerDetailsReducer,
});

export default rootReducer;