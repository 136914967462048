
// @flow
import React from 'react'
import { FaRegTimesCircle } from "react-icons/fa"
import '../styles/TopBar.scss'

export default function TopBar(props) {
  return (
    <div className="top-bar">
      {props.leftButton && (
        <div className="back-btn" onClick={props.leftButton}>
          <FaRegTimesCircle size="3em" color={'#000'} />
        </div>
      )}
      <div className="page-title">Envoyé ma commande</div>
    </div>
  )
}