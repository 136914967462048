import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import RestaurantRealtime from './components/RestaurantRealtime'
import Home from './components/Home'

const AppRouter = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/:qrid/" component={RestaurantRealtime} />
    <Redirect to="/" />
  </Switch>
)

export default AppRouter
