/**
 * @format
 * @flow
 */

import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_CART_TOTAL,
  UPDATE_COMMENT,
  UPDATE_RESTO_INFO,
  SET_FLUSH_CART,
  RESET_CART,
  APPLY_DISCOUNT,
  RETURN_CART_FEES,
  SET_CART_TOTAL_CONFIRM,
  APPLY_COUPON,
  UPDATE_TABLE
} from '../actions/cartAction';
import { CartType } from '../flowObjectType'

const cartObject: CartType = {
  createdAt: null,
  qrId: '',
  restoId: '',
  restoName: '',
  restoPostal: '',
  canOrder: false,
  products: [],
  total: 0,
  totalHT: 0,
  totalVat: 0,
  totalConfirm: 0,
  totalProducts: 0,
  subtotal: 0,
  discount: 0,
  comment: '',
  customerNbr: 0,
  table: '',
  orderSchedule: [],
  deliverySchedule: [],
  flushCart: false,
  coupon: null,
}

export default function foo(state: Object = { ...cartObject }, action: Object) {
  switch (action.type) {
    case UPDATE_COMMENT:
      return { ...state, comment: action.payload.comment }
    case ADD_PRODUCT:
      return {
        ...state,
        createdAt: state.createdAt === null ? new Date() : state.createdAt,
        products: [...state.products, action.payload.product],
      }
    case UPDATE_PRODUCT:
      state.products[action.payload.idx] = action.payload.product
      return { ...state, products: state.products }
    case DELETE_PRODUCT:
      state.products.splice(action.payload.idx, 1)
      return { ...state, products: state.products }
    case UPDATE_CART_TOTAL:
      return {
        ...state,
        total: action.payload.cartTotal,
        totalHT: action.payload.cartTotalHT,
        totalVat: action.payload.cartTotalVat,
        totalProducts: action.payload.cartTotalProducts,
        subtotal: action.payload.cartSubtotal,
      }
    case UPDATE_RESTO_INFO:
      return {
        ...state,
        qrId: action.payload.qrId,
        restoId: action.payload.restoId,
        restoName: action.payload.restoName,
        restoPostal: action.payload.restoPostal,
        canOrder: action.payload.canOrder,
        restoInformations: action.payload.restoInformations,
      }
    case SET_FLUSH_CART:
      return {
        ...state,
        flushCart: true,
      }
    case RESET_CART:
      return {
        ...state,
        createdAt: null,
        coupon: null,
        flushCart: false,
        products: [],
        total: 0,
        totalHT: 0,
        totalVat: 0,
        totalProducts: 0,
        totalConfirm: 0
      }
    case RETURN_CART_FEES:
      return {
        ...state,
        discountTotal: action.payload.discountTotal,
        feeTotal: action.payload.feeTotal,
      }
    case APPLY_DISCOUNT: {
      const { total, totalHT, totalVat, totalProducts, products, subtotal } = getCartPrice(state.products, action.payload.discount);
      return {
        ...state,
        total,
        totalHT,
        totalVat,
        totalProducts,
        products,
        subtotal,
        discount: action.payload.discount,
      }
    }
    case SET_CART_TOTAL_CONFIRM:
      return {
        ...state,
        totalConfirm: action.payload.totalConfirm,
      }
    case APPLY_COUPON: {
      return {
        ...state,
        coupon: action.payload.coupon
      }
    }
    case UPDATE_TABLE: {
      return {
        ...state,
        table: action.payload.table
      }
    }
    default:
      return state;
  }
}

const getProductPrice = (product: ProductType, quantity: number, discount: number): { total: number, totalHT: number, totalVat: number } => {
  const rubric = product.rubric || []

  if (typeof product === 'undefined') return { total: 0, totalHT: 0, totalVat: 0 }
  let total = quantity * product.unitPrice
  let totalHT = quantity * product.unitPriceHT

  rubric.forEach((section) => {
    section.data.forEach((rubric) => {
      if (rubric.checked && rubric.unitPrice > 0) {
        total += quantity * rubric.unitPrice * (rubric.quantity || 1)
        totalHT += quantity * rubric.unitPriceHT * (rubric.quantity || 1)
      }
    })
  })

  if (product.tableDiscount && product.tableDiscount.discount) {
    total = total * (1 - product.tableDiscount.discount / 100)
    totalHT = totalHT * (1 - product.tableDiscount.discount / 100)
  }

  let subtotal = total

  // apply cart discount
  if (discount) {
    if (!product.tableDiscount || (product.tableDiscount && product.tableDiscount.canDiscount)) {
      total = total * (1 - discount / 100)
      totalHT = totalHT * (1 - discount / 100)
    }
  }

  return { total, totalHT, totalVat: total - totalHT, subtotal }
}

const getCartPrice = (products, discount) => {
  if (products.length === 0) {
    return { total: 0, totalHT: 0, totalVat: 0, totalProducts: 0, subtotal: 0, products: [] }
  }
  let cartTotal = 0
  let cartTotalHT = 0
  let cartTotalVat = 0
  let cartTotalProducts = 0
  let cartSubtotal = 0
  products.forEach((product) => {
    const { total, totalHT, totalVat, subtotal } = getProductPrice(product, product.quantity, discount)
    product.total = total;
    product.totalHT = totalHT;
    product.totalVat = totalVat;
    product.subtotal = subtotal;
    cartTotal += total
    cartTotalHT += totalHT
    cartTotalVat += totalVat
    cartTotalProducts += product.quantity
    cartSubtotal += subtotal
  })
  return { total: cartTotal, totalHT: cartTotalHT, totalVat: cartTotalVat, totalProducts: cartTotalProducts, subtotal: cartSubtotal, products }
}
