import React, { Component } from 'react';

export default class Home extends Component {
  componentDidMount() {
    window.location.replace('https://we-eats.com')
  }
  render() {
    return (
      <div/>
    )
  }
}
