/**
 * @format
 * @flow
 */

import { SET_CUSTOMER_DETAILS } from '../actions/customerDetailsAction';
import { CustomerDetailsType } from '../flowObjectType'

const initialState: CustomerDetailsType = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  addressComplement: '',
  postal: '',
  rememberMe: false,
}

export default function foo(state: Object = { ...initialState }, action: Object) {
  switch (action.type) {
    case SET_CUSTOMER_DETAILS:
      return { ...state, ...action.payload.customerDetails }
    default:
      return state;
  }
}
