import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/database'
import firebaseConfig from '../firebase.config'

firebase.initializeApp(firebaseConfig)
firebase.analytics()

export const app = firebase.app
export const auth = firebase.auth
export const firestore = firebase.firestore
export const analytics = firebase.analytics
export const functions = firebase.functions
export const database = firebase.database