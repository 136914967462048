import React, { Component } from 'react';
import { BreakpointProvider } from 'react-socks';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducers from './reducers';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createEncryptor from 'redux-persist-transform-encrypt'

import './styles/App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './AppRouter'

import { PersistGate } from 'redux-persist/integration/react'

const encryptor = createEncryptor({
  secretKey: '#my@-super@-secret-!key#',
  onError: function(error) {
    persistor.purge()
  }
})

const persistConfig = {
  key: window.location.pathname.split('/')[1],
  storage,
  transforms: [encryptor]
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const persistor = persistStore(store)

class App extends Component {
  render() {
    return (
      <Router>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BreakpointProvider>
              <AppRouter />
            </BreakpointProvider>
          </PersistGate>
        </Provider>
      </Router>
    );
  }
}

export default App;
