/**
 * @format
 * @flow
 */

import React, { Component } from 'react'
import { Breakpoint } from 'react-socks'
import { Container, Form, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import Lottie from 'react-lottie'
import Swal from 'sweetalert2';
import dayjs from 'dayjs'

import { resetCart, updateRestoInfo, setFlushCart, setCartTotalConfirm } from '../actions/cartAction'
import { updateRestoSettings, updateOrderSchedule } from '../actions/settingsAction';
import * as animationData from '../assets/loading.json'
import { CartType, ProductType } from '../flowObjectType'
import { firestore, database } from '../services/firebase'
import '../styles/Cart.scss'
import TopBar from './TopBar'
import { setCustomerDetails } from '../actions/customerDetailsAction'
import ProductCartRowRealtime from './ProductCartRowRealtime'
import ProductDetailRealtime from './ProductDetailRealtime'

require('dayjs/locale/fr')
dayjs.locale('fr')
type Props = {
  match: Object,
  cart: CartType,
  myCart: CartType,
  customerDetails: Object,
  updateRestoInfo: Function,
  setCustomerDetails: Function,
  updateRestoSettings: Function,
  updateOrderSchedule: Function,
  resetCart: Function,
  settings: Object,
  setCartTotalConfirm: Function,
}

type State = {
  modalVisible: boolean,
  showProductDetail: boolean,
  selectedProduct: ?ProductType,
  selectedIdx: number,
  loading: boolean,
  cart: CartType
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}
class Cart extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showProductDetail: false,
      modalVisible: false,
      selectedProduct: null,
      selectedIdx: 0,
      loading: false,
      cart: this.props.cart,
      myCart: this.props.myCart
    }
  }

  onClickCartProduct = (index) => e => {
    this.setState({
      selectedProduct: this.props.myCart.products[index],
      showProductDetail: true,
      selectedIdx: index
    });
  };

  toggleModal = () => {
    this.setState(prevState => ({
      showProductDetail: !prevState.showProductDetail
    }))
  }

  getFinalProduct = () => {
    const { products } = this.state.cart
    let newProduct = []
    Object.keys(products).map((userId) => (
      Object.keys(products[userId].map((product, index) => {
        const { rubric, ...noRubric } = product
        newProduct[userId][index] = { ...noRubric, rubric: [] };
        (product.rubric || []).forEach((sections) => {
          const rubricData = sections.data.filter((item) => item.checked === true)
          newProduct[userId][index].rubric.push({ rubricName: sections.rubricName, data: rubricData })
        })
        return []
      }))
    ))
    return newProduct
  }

  getAllProducts = async () => {
    let newProducts = []
    const { restoId, qrId } = this.props
    await database().ref(`${restoId}/${qrId}/users/`).get().then((snapshot) => {
      if (snapshot.exists()) {
        const users = snapshot.val()
        for (const [id, user] of Object.entries(users)) {
          if (id === this.props.userId) {
            user.cart.products?.forEach((product, index) => {
              const { image, ...noImage } = product
              newProducts.push(noImage)
            })
          }
        }
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
    return newProducts
  }

  resetUserCart = async () => {
    const { restoId, qrId } = this.props
    await database().ref(`${restoId}/${qrId}/users/`).get().then((snapshot) => {
      if (snapshot.exists()) {
        const users = snapshot.val()
        for (const [id] of Object.entries(users)) {
          database().ref(`${restoId}/${qrId}/users/${id}/cart/products`).set([])
          database().ref(`${restoId}/${qrId}/users/${id}/cart/`).update({
            total: 0, totalHT: 0, totalVat: 0, totalProducts: 0, subtotal: 0
          })
        }
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  _getVatDetail = (products, additionalFee) => {
    let vatDetail = {}
    if (additionalFee !== null) {
      vatDetail[additionalFee.vat] = additionalFee.totalVat
    }
    products.forEach(product => {
      let total = product.quantity * product.unitPrice
      let totalHT = product.quantity * product.unitPriceHT
      if (typeof product.discount !== 'undefined' && product.discount !== null && !product.discount.isFidelityDiscount) {
        total = total * (1 - product.discount.percentage / 100)
        totalHT = totalHT * (1 - product.discount.percentage / 100)
      }

      const productVat = total - totalHT
      if (typeof vatDetail[product.vat] === 'undefined')
        vatDetail[product.vat] = productVat
      else
        vatDetail[product.vat] += productVat

      product.rubric.forEach((section) => {
        section.data.forEach((rubric) => {
          if (rubric.unitPrice > 0) {
            const rubricVat = rubric.unitPrice - rubric.unitPriceHT
            if (typeof vatDetail[rubric.vat] === 'undefined')
              vatDetail[rubric.vat] = rubricVat
            else
              vatDetail[rubric.vat] += rubricVat
          }
        })
      })
    })

    return vatDetail
  }

  _submitForm = (event) => {
    if (event && event.preventDefault)
      event.preventDefault()

    if (typeof this.state.cart.canOrder === undefined || this.state.cart.canOrder === false) {
      Swal.fire("Attention", "La commande sur internet est momentanement suspendu", "error");
      return
    }

    this.setState({ loading: true });
    return this.confirmCart(true);
  }

  confirmCart = async (redirect = false) => {
    const { totalProducts } = this.props.myCart
    let { total, totalHT, totalVat, subtotal } = this.props.myCart

    const products = await this.getAllProducts()
    const docRef = firestore().collection(`Restaurant/${this.props.settings.restoId}/TableOrder`).doc()
    const orderDate = firestore.FieldValue.serverTimestamp()

    const jsonOrder = {
      qrId: this.props.settings.qrId,
      table: this.props.cart.table,
      createdAt: orderDate,
      confirmedAt: orderDate,
      isPaid: false,
      isClosed: false,
      products: products,
      productNbr: totalProducts,
      canceledReason: 0,
      status: this.props.settings.autoAcceptOrder ? 2 : 1,
      subtotal,
      total,
      totalHT,
      totalVat,
      vatDetail: {},//this._getVatDetail(products, additionalFee),
      payments: []
    }

    docRef.set(jsonOrder)
      .then(() => {
        setTimeout(() => {
          this.resetUserCart()
          this.setState({ loading: false });
          this.props.closeModal()
          Swal.fire("Commande envoyée en cuisine", "vous serez bientôt servi", "success");
        }, 2000)
      }).catch(err => console.log(err))
  }


  closeProductModal = () => {
    this.setState({ showProductDetail: false })
  }

  getOrderTotal = () => {
    let total = this.props.myCart.total;
    return total;
  }

  render() {
    if (this.props.myCart === undefined)
      return <></>
    return (
      <Container className="container" id="checkout">
        {
          this.props.settings.color && (
            <style> {`body {
              --website-color: ${this.props.settings.color}
            }`}
            </style>
          )
        }
        <Breakpoint medium up>
          <TopBar leftButton={this.props.closeModal} />
          <Form className="form">

            <div className="cart">
              <div className="cart-my-order">
                <div className="cart-title">Ma commande</div>
              </div>
              <div className="cart-product-container">
                {this.props.myCart.products.map((product, index) => (
                  <ProductCartRowRealtime
                    myCart={this.props.myCart}
                    product={product}
                    key={index}
                    index={index}
                    onClick={this.onClickCartProduct}
                  />
                ))}
              </div>

              <div className="cart-total-container">
                <div className="cart-row" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <div className="cart-title">TOTAL</div>
                  <div className="cart-title">
                    {`${this.getOrderTotal().toFixed(2)}€`}
                  </div>
                </div>
              </div>

              {!this.state.loading && (
                <div className="footer">
                  <button
                    type="submit"
                    onClick={this._submitForm}
                  >
                    <div className={'confirm-btn'}>Confirmez ma commande</div>
                  </button>
                </div>
              )}

              {this.state.loading && (
                <div className="footer">
                  <Lottie options={defaultOptions} style={{width: '180px'}}/>
                </div>
              )}
            </div>
          </Form>
        </Breakpoint>

        <Breakpoint small down>
          <TopBar leftButton={this.props.closeModal} />

          <div className="cart-my-order">
            <div className="cart-title">Ma commande</div>
          </div>
          <div className="cart-product-container">
            {this.props.myCart.products.map((product, index) => (
              <ProductCartRowRealtime
                myCart={this.props.myCart}
                product={product}
                key={index}
                index={index}
                onClick={this.onClickCartProduct}
              />
            ))}
          </div>

          <div className="cart-total-container">
            <div className="cart-row" style={{ width: '100%', justifyContent: 'space-between' }}>
              <div className="cart-title">TOTAL</div>
              <div className="cart-title">{`${this.getOrderTotal().toFixed(2)}€`}</div>
            </div>
          </div>

          <hr />

          <Form autoComplete="off">
            {!this.state.loading && (
              <div className="footer">
                <button
                  type="submit"
                  onClick={this._submitForm}
                >
                  <div className={'confirm-btn'}>Confirmez ma commande</div>
                </button>
              </div>
            )}
            {this.state.loading && (
              <Lottie options={defaultOptions} style={{width: '120px'}}/>
            )}
          </Form>
        </Breakpoint>

        <Modal
          show={this.state.showProductDetail}
          className="custom-map-modal"
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          onHide={this.closeProductModal}
        >
          <ProductDetailRealtime
            myCart={this.props.myCart}
            product={this.state.selectedProduct}
            closeModal={this.closeProductModal}
            updateIndex={this.state.selectedIdx}
            options={this.props.options}
            readOnly={this.props.readOnly}
          />
        </Modal>
      </Container >
    )
  }
}

//redux

const mapStateToProps = (state) => {
  return {
    restoId: state.settings.restoId,
    qrId: state.settings.qrId,
    userId: state.settings.userId,
    settings: state.settings,
    customerDetails: state.customerDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestoSettings: (a, b, c, d, e, f, g, h) => dispatch(updateRestoSettings(a, b, c, d, e, f, g, h)),
    resetCart: () => dispatch(resetCart()),
    updateRestoInfo: (a, b, c, d, e) => dispatch(updateRestoInfo(a, b, c, d, e)),
    setCustomerDetails: (customerDetails) => dispatch(setCustomerDetails(customerDetails)),
    updateOrderSchedule: (schedule) => dispatch(updateOrderSchedule(schedule)),
    setCartTotalConfirm: (total) => dispatch(setCartTotalConfirm(total)),
    setFlushCart: () => dispatch(setFlushCart()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
