/**
 * @format
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import swal from 'sweetalert2';

import { CartType, ProductType } from '../flowObjectType'
import '../styles/ProductDetail.scss'
import { BsTrashFill } from "react-icons/bs";
import { FaRegTimesCircle } from "react-icons/fa"
const _ = require('lodash')

type State = {
  productQuantity: number
}

type Props = {
  show: boolean,
  closeModal: Function,
  addProduct: Function,
  updateProduct: Function,
  updateCartTotal: Function,
  deleteProduct: Function,
  myCart: CartType,
  dropdown: any,
  product: ProductType,
  updateIndex?: number,
  productQuantity?: number,
  isRestoDisabled?: boolean,
  clickAndCollect?: boolean,
  type?: number,
  demo: boolean,
  options: any,
  readOnly?: boolean
}

const Toast = swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 1800,
})

const swalWithBootstrapButtons = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: false
})

class ProductDetail extends Component<Props, State> {
  dropdown: any
  hasRubric: boolean
  rubric: []
  total: number
  totalHT: number
  totalVat: number
  product

  constructor(props: any) {
    super(props)
    this.dropdown = this.props.dropdown
    this.rubric = []
    this.total = 0
    this.totalHT = 0
    this.totalVat = 0
    this.subtotal = 0
    this.options = this.props.options
    this.product = {}
  }

  state = {
    productQuantity: this.props.product.quantity ? this.props.product.quantity : 1
  }

  componentWillMount = () => {
    this.product = _.cloneDeep(this.props.product)
    if (this.product.rubric !== undefined && this.product.rubric.length) {
      this.rubric = _.cloneDeep(this.product.rubric)
    }



    if (this.product.rubrics && this.product.rubrics.length)
      this.rubric = this.getProductOptions(this.product)
    this.getProductPrice(this.product, this.state.productQuantity, false)
  }

  addQuantity = () => {
    if (this.state.productQuantity < 50) {
      this.getProductPrice(this.product, this.state.productQuantity + 1, false)
      this.setState((prevState) => ({ productQuantity: prevState.productQuantity + 1 }))
    }
  }

  removeQuantity = () => {
    if (this.state.productQuantity > 1) {
      this.getProductPrice(this.product, this.state.productQuantity - 1, false)
      this.setState((prevState) => ({ productQuantity: prevState.productQuantity - 1 }))
    }
  }

  getProductOptions = (product) => {
    const productRubrics = [];
    for (let i = 0; i < product.rubrics.length; ++i) {
      const platRubric = product.rubrics[i];

      for (let ii = 0; ii < this.options.length; ++ii) {
        const r = _.cloneDeep(this.options[ii])
        if (!r.shared && r.id === platRubric) {
          productRubrics.push(r);
        }
      }
    }
    return productRubrics;
  }

  selectRubric = (rubric: { checked: boolean }, checked: boolean, section: any) => () => {
    let totalSelectedQuantity = 0;
    for (let i = 0; i < section.data.length; ++i) {
      totalSelectedQuantity += section.data[i].quantity || 0;
    }
    if (totalSelectedQuantity === 0) {
      totalSelectedQuantity += section.selected
    }


    if (checked === true && totalSelectedQuantity >= section.selectMax && section.selectMax > 1) return

    if (checked === true) {
      if (section.selectMax === 1 && totalSelectedQuantity >= 1) { // online one choice
        section.data.forEach(choice => {
          choice.checked = false
        })
      }
      else {
        if (section.allowSameMultiple) {
          rubric.quantity = 1;
        }
        section.selected = section.selected ? section.selected + 1 : 1
      }
    }
    else {
      if (section.allowSameMultiple) {
        rubric.quantity = 0;
      }
      section.selected = section.selected ? section.selected - 1 : 0
    }
    rubric.checked = checked
    this.getProductPrice(this.product, this.state.productQuantity, false)
    this.forceUpdate()
  }

  rubricQuantityAdd = (rubric, section) => {
    let totalSelectedQuantity = 0;
    for (let i = 0; i < section.data.length; ++i) {
      totalSelectedQuantity += section.data[i].quantity || 0;
    }
    if (totalSelectedQuantity + 1 <= section.selectMax) {
      rubric.quantity += 1;
    }

    this.getProductPrice(this.product, this.state.productQuantity, false)
    this.forceUpdate();
  }

  rubricQuantityRemove = (rubric, section) => {
    if (rubric.quantity > 1) {
      rubric.quantity -= 1;
    }
    else {
      rubric.quantity = 0;
      rubric.checked = false;
    }

    this.getProductPrice(this.product, this.state.productQuantity, false)
    this.forceUpdate();
  }

  verifyProduct = () => {
    let error = false
    this.rubric.forEach((section) => {
      if (section.necessary === true && (typeof section.selected === 'undefined' || section.selected === 0)) {
        error = true
      }
    })
    return error
  }

  addProduct = async () => {
    if (this.verifyProduct()) {
      swal.fire("Selection incomplète", "Veuillez selectionner les choix obligatoires", "error");
      return
    }

    const product = _.cloneDeep(this.product)

    const newProduct = {
      id: product.id,
      quantity: this.state.productQuantity,
      name: product.name,
      description: product.description ? product.description : '',
      unitPrice: product.unitPrice,
      unitPriceHT: product.unitPriceHT,
      discount: product.discount || null,
      tableDiscount: product.tableDiscount || null,
      takeawayDiscount: product.takeawayDiscount || null,
      deliveryDiscount: product.deliveryDiscount || null,
      image: product.image || null,
      vat: product.vat,
      rubric: _.cloneDeep(this.rubric),
      total: this.total,
      totalHT: this.totalHT,
      totalVat: this.totalVat,
      subtotal: this.subtotal,
    }

    await this.props.addProduct(newProduct)
    await this.getCartPrice()
    // this.dropdown.alertWithType('success', 'Produit ajouter', '');

    Toast.fire({
      icon: 'success',
      title: 'Produit ajouter'
    })
    this.props.closeModal()


  }

  updateProduct = async () => {
    if (this.verifyProduct()) {
      swal.fire("Selection incomplète", "Veuillez selectionner les choix obligatoires", "error");
      return
    }

    const { product, updateIndex } = this.props
    const newProduct = {
      id: product.id,
      quantity: this.state.productQuantity,
      name: product.name,
      description: product.description ? product.description : '',
      unitPrice: product.unitPrice,
      unitPriceHT: product.unitPriceHT,
      discount: product.discount || null,
      tableDiscount: product.tableDiscount || null,
      takeawayDiscount: product.takeawayDiscount || null,
      deliveryDiscount: product.deliveryDiscount || null,
      image: product.image || null,
      vat: product.vat,
      rubric: this.rubric,
      total: this.total,
      totalHT: this.totalHT,
      totalVat: this.totalVat,
      subtotal: this.subtotal,
    }

    await this.props.updateProduct(newProduct, updateIndex)
    await this.getCartPrice()
    // this.dropdown.alertWithType('success', 'Panier modifier', '');
    this.props.closeModal()

  }

  getCartPrice = () => {
    const cart = this.props.myCart
    if (Object.keys(cart.products).length === 0) {
      this.props.updateCartTotal(0, 0, 0, 0, 0)
      return
    }
    let cartTotal = 0
    let cartTotalHT = 0
    let cartTotalVat = 0
    let cartTotalProducts = 0
    let cartSubtotal = 0

    const products = cart.products ? [].concat.apply([], Object.values(cart.products)) : []
    products.forEach((product) => {
      const { total, totalHT, totalVat, subtotal } = this.getProductPrice(product, product.quantity, true)
      cartTotal += total
      cartTotalHT += totalHT
      cartTotalVat += totalVat
      cartTotalProducts += product.quantity
      cartSubtotal += subtotal
    })

    this.props.updateCartTotal(cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal)
  }


  getProductPrice = (product: ProductType, quantity: number, forTotal: boolean): { total: number, totalHT: number, totalVat: number, subtotal: number } => {
    const rubric = forTotal ? product.rubric : this.rubric

    if (typeof product === 'undefined') return { total: 0, totalHT: 0, totalVat: 0 }
    let total = quantity * product.unitPrice
    let totalHT = quantity * product.unitPriceHT

    rubric?.forEach((section) => {
      section.data.forEach((rubric) => {
        if (rubric.checked && rubric.unitPrice > 0) {
          if (section.allowSameMultiple) {
            total += quantity * rubric.unitPrice * (rubric.quantity || 0)
            totalHT += quantity * rubric.unitPriceHT * (rubric.quantity || 0)
          }
          else {
            total += quantity * rubric.unitPrice
            totalHT += quantity * rubric.unitPriceHT
          }
        }
      })
    })

    if (product.tableDiscount && product.tableDiscount.discount) {
      total = total * (1 - product.tableDiscount.discount / 100)
      totalHT = totalHT * (1 - product.tableDiscount.discount / 100)
    }

    let subtotal = total

    // apply cart discount
    if (this.props.myCart.discount) {
      if (!product.tableDiscount || (product.tableDiscount && product.tableDiscount.canDiscount)) {
        total = total * (1 - this.props.myCart.discount / 100)
        totalHT = totalHT * (1 - this.props.myCart.discount / 100)
      }
    }

    // if (typeof product.discount !== 'undefined' && product.discount !== null && !product.discount.isFidelityDiscount) {
    //   total = total * (1 - product.discount.percentage / 100)
    //   totalHT = totalHT * (1 - product.discount.percentage / 100)
    // }

    this.total = total
    this.totalHT = totalHT
    this.totalVat = total - totalHT
    this.subtotal = subtotal
    return { total, totalHT, totalVat: this.totalVat, subtotal }
  }

  deleteProduct = async () => {

    const { updateIndex } = this.props
    this.props.deleteProduct(updateIndex)
    this.getCartPrice()
    // this.dropdown.alertWithType('success', 'Produit supprimer');
    this.props.closeModal()
  }

  deleteProductAlert = () => {

    swalWithBootstrapButtons.fire({
      title: 'Confirmer la suppression ?',
      showDenyButton: false,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: `Oui supprimer du panier`,
      denyButtonText: `Non`,
      reverseButtons: true
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.deleteProduct()
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    })

    // var r = window.confirm("Confirmer la suppression ?");
    // if (r === true) {
    //   this.deleteProduct()
    // }
  }

  getLabel = () => {
    if (this.props.demo)
      return 'Compte demo'
    if (this.props.isRestoDisabled)
      return 'Restaurant fermé'
    if (!this.product.displayApp)
      return 'Produit indisponible'
    return `Ajouter au panier (${this.subtotal.toFixed(2)} €)`
  }

  renderProductInfo = () => {
    const { product } = this.props
    if (typeof product === 'undefined') return <div />
    const hasImage = typeof product.image !== 'undefined' && product.image.path !== '' && product.image.enable
    const unitPrice = parseFloat(product.unitPrice);

    let discountPrice = ``;
    let hasDiscount = false;
    let discountLabel = '';
    if (product.tableDiscount && product.tableDiscount.discount) {
      discountPrice = unitPrice * (1 - product.tableDiscount.discount / 100)
      hasDiscount = true;
      discountLabel = `${product.tableDiscount.discount}% remise immédiate `
    }
    const isFidelityDiscount = hasDiscount ? product.discount && product.discount.isFidelityDiscount : false

    return (
      <div className="product-info-block">

        <div className="back-btn" onClick={this.props.closeModal}>
          <FaRegTimesCircle size="3em" color={'#000'} />
        </div>

        {typeof this.props.updateIndex !== 'undefined' && (
          <button className="delete-btn" onClick={this.deleteProductAlert}><BsTrashFill size="1.2em" /></button>
        )}
        {hasImage && (

          <img
            alt="produit"
            className="product-img"
            src={product.image.path}
          />
        )}

        {!hasImage &&
          <div style={{ height: 50 }} />
        }


        <div className="productName">
          {this.product.name}
        </div>

        <div />

        {product.description != null && product.description.trim() !== '' && (
          <div className="product-description">{product.description}</div>
        )}

        {hasDiscount && !isFidelityDiscount && (
          <div className="product-price" style={{ alignItems: 'center' }}>
            <span style={{ textDecoration: 'line-through' }}>{unitPrice.toFixed(2)} €</span>
            <span> {discountPrice.toFixed(2)} €</span>
            <div style={{ flexDirection: 'row' }}>
              <span>{discountLabel}</span>
              {/* <img
                source={require('../../assets/icon/banking.png')}
                resizeMode="contain"
                style={{ width: 25, height: 25 }}
              /> */}
            </div>
          </div>
        )}

        {!hasDiscount && product.unitPrice > 0 && (
          <div className="product-price">{unitPrice.toFixed(2)} €</div>
        )}
      </div>
    )
  }

  renderQuantity = () => {
    const { product } = this.props
    if (typeof product === 'undefined') return <div />
    return (
      <div className="quantityBlock">
        <button className="quantityBtn" onClick={this.removeQuantity}>-</button>
        <button className="totalQty">{this.state.productQuantity}</button>
        <button className="quantityBtn" onClick={this.addQuantity}>+</button>
      </div>
    )
  }

  renderContent = () => {
    return (
      <div className="content-block">
        {this.rubric.map((section) => {
          const sectionHeader = this.renderSectionHeader({ section })
          return section.data.map((item, idx) => {
            const sectionItem = this.renderRubric({ item, index: section.index, section })
            if (idx === 0) return <div key={section.index + section.rubricName}>{sectionHeader}{sectionItem}</div>
            return sectionItem
          })
        })}
      </div>
    )
  }

  renderSectionHeader = ({ section }: { section: any }) => {
    const { rubricName, selectMin, selectMax, necessary } = section
    let name = ''
    if (selectMin === selectMax && selectMax !== 1)
      name = `${selectMin} ${rubricName} `
    else if (selectMax !== 1)
      name = `${rubricName} (${selectMax} maximum)`
    else
      name = rubricName
    return (
      <div
        className="section-header"
        key={section.rubricName + section.index}
      >
        <div>{name} {necessary && (' ( obligatoire )')}</div>
      </div>

    )
  }

  renderRubric = ({ item, index, section }: { item: any, index: number, section: any }) => {
    if (item.displayApp === false) return <div />
    const unitPrice = item.unitPrice ? `+ ${parseFloat(item.unitPrice).toFixed(2)} €` : ''
    return (
      <div
        className="rubricItem"
        onClick={this.selectRubric(item, !item.checked, section)}
        key={section.index + item.name}
      >

        {!this.props.readOnly && (
          <div className="ui radio checkbox">
            <Form.Check
              size={"lg"}
              checked={item.checked || false}
              type={section.necessary && section.selectMax === 1 ? 'radio' : 'checkbox'}
              onChange={() => { }}
              color="red"
            />
          </div>
        )}
        <div className="rubric-name">
          {item.name.startsWith('www.') && (
            <span><a target="_blank" rel="noopener noreferrer" href={`//${item.name}`}>{item.name}</a></span>
          )}
          {!item.name.startsWith('www.') && (
            <span>{item.name}</span>
          )}
        </div>
        <div className="rubric-price">
          <span>{unitPrice}</span>
        </div>

        {section.allowSameMultiple && item.checked && (
          <div className="rubric-quantity-container" onClick={(e) => e.stopPropagation()}>
            <span className="rubric-quantity-control" onClick={() => this.rubricQuantityRemove(item, section)}>-</span>
            <span className="rubric-quantity-number">{item.quantity}</span>
            <span className="rubric-quantity-control" onClick={() => this.rubricQuantityAdd(item, section)}>+</span>
          </div>
        )}
      </div>
    )
  }

  renderOption = ({ item, index, section }: { item: any, index: number, section: any }) => {
    if (item.displayApp === false) return <div />
    const unitPrice = item.unitPrice ? `+ ${parseFloat(item.unitPrice).toFixed(2)} €` : ''
    return (
      <div
        className="rubricItem"
        onClick={this.selectRubric(item, !item.checked, section)}
        key={section.index + item.name}
      >
        <div>
          <Form.Check
            size={"lg"}
            checked={item.checked || false}
            type={item.necessary ? 'radio' : 'checkbox'}
            onChange={() => { }}
          />
        </div>
        <div className="rubric-name">
          <span>{item.name}</span>
        </div>
        <div className="rubric-price">
          <span>{unitPrice}</span>
        </div>
      </div>
    )

  }

  renderFooter = () => {
    return (
      <div>
        {this.props.updateIndex == null && (
          <div className="modalFooter">
            <div className="confirm-btn" onClick={this.addProduct}>
              {this.getLabel()}
            </div>
          </div>
        )}

        {this.props.updateIndex != null && (
          <div className="modalFooter">
            <div className="confirm-btn" onClick={this.updateProduct}>
              {`Mettre à jour (${this.subtotal.toFixed(2)} €)`}
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    return (
      <div className="modalContainer">
        <div className="scrollable-content">
          {this.renderProductInfo()}
          {this.renderContent()}
          {!this.props.readOnly && (
            this.renderQuantity()
          )}
        </div>
        {!this.props.readOnly && (
          this.renderFooter()
        )}
      </div>
    );
  }
}

// redux
const mapStateToProps = (state) => {
  return {
    // cart: state.cart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // addProduct: (product) => dispatch(addProduct(product)),
    // updateProduct: (product, idx) => dispatch(updateProduct(product, idx)),
    // updateCartTotal: (cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal) => dispatch(updateCartTotal(cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal)),
    // deleteProduct: (idx) => dispatch(deleteProduct(idx))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);