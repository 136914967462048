const config = {
  apiKey: "AIzaSyAh7gfyWhTfggbLXyC7o-krEIiX14fi53Y",
  authDomain: "we-eats-88888.firebaseapp.com",
  databaseURL: "https://we-eats-88888.firebaseio.com",
  projectId: "we-eats-88888",
  storageBucket: "we-eats-88888.appspot.com",
  messagingSenderId: "265224517267",
  appId: "1:265224517267:web:00344c09ac2c66795f3f66",
  measurementId: "G-FRGL0JNJVH"
};

export default config